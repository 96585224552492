<template>
  <div>
    <vx-card class="card">
      <div class="flex justify-end">
        <vs-button
          size="medium"
          v-round
          class="newuser-btn mb-4"
          @click="
            $router.push({
              name: 'user-create',
            })
          "
        >
          <plus-icon size="1.5x" class="button-icon"></plus-icon>New
          User</vs-button
        >
      </div>

      <vs-table :data="users">
        <template slot="thead">
          <vs-th>Name </vs-th>
          <vs-th>Role </vs-th>
          <vs-th> Email</vs-th>
          <vs-th> &nbsp;</vs-th>
          <vs-th> &nbsp;</vs-th>
        </template>

        <template slot-scope="{ data }">
          <vs-tr :key="indextr" v-for="(tr, indextr) in data">
            <vs-td :data="data[indextr].fullName">
              {{ data[indextr].fullName }}
            </vs-td>

            <vs-td :data="data[indextr].userType">
              {{ data[indextr].userType }}
            </vs-td>

            <vs-td :data="data[indextr].email">
              {{ data[indextr].email }}
            </vs-td>

            <vs-td :data="tr.id">
              <a class="underline" @click="sendCredentials(tr._id)">{{
                tr.credentialsSent ? "Resend credentials" : "Send credentials"
              }}</a>
            </vs-td>
            <vs-td :data="tr.id">
              <a
                class="underline"
                @click="
                  $router.push({
                    name: 'user-create',
                    query: { id: tr._id },
                  })
                "
                >Edit</a
              >
            </vs-td>
          </vs-tr>
        </template>
      </vs-table>

      <div>
        <vs-row class="px-5 my-10 flex justify-between items-center">
          <div class="flex items-center gap-4">
            <span>Records per page</span>
            <vs-select v-model="limit" class="records-per-page">
              <vs-select-item
                v-for="(item, index) in limitValue"
                :key="index"
                class="w-1/12"
                :text="item.text"
                :value="item.value"
              />
            </vs-select>
          </div>

          <v-pagination
            v-model="pageNumber"
            :page-count="totalPage"
          ></v-pagination>
        </vs-row>
      </div>
    </vx-card>
  </div>
</template>

<script>
import { PlusIcon } from "vue-feather-icons";
import { mapActions } from "vuex";
import vPagination from "@/views/components/pagination";
import "vue-select/dist/vue-select.css";
import "vue-select/src/scss/vue-select.scss";
export default {
  name: "UserList",
  components: {
    PlusIcon,
    vPagination,
  },
  data() {
    return {
      users: [],
      pageNumber: 1,
      totalData: 0,
      limit: 50,
      limitValue: [
        { text: "1", value: 1 },
        { text: "10", value: 10 },
        { text: "20", value: 20 },
        { text: "50", value: 50 },
        { text: "100", value: 100 },
      ],
    };
  },
  methods: {
    ...mapActions("admin", [
      "fetchAllAdminUsers",
      "sendCredentialsToAdminUser",
    ]),

    async sendCredentials(id) {
      await this.sendCredentialsToAdminUser(id)
        .then((result) => {
          this.$vs.notify({
            title: "User credentials",
            text: `The username and password has been successfully sent!`,
            color: "success",
            iconPack: "feather",
            position: "top-right",
            icon: "icon-check-circle",
          });
          this.getAllAdminUsers(this.pageNumber, this.limit);
        })
        .catch((err) => {});
    },

    async getListSetting() {
      const payload = {
        name: this.$route.name,
        partnerId: JSON.parse(localStorage.getItem("user"))._id,
      };
      await this.$store
        .dispatch("listSetting/updatelistSetting", payload)
        .then((res) => {
          this.limit = parseInt(res.data.data.limit) || this.limit;
        })
        .catch((err) => {
          
        });
    },
    async saveListSetting(limit) {
      const payload = {
        name: this.$route.name,
        limit,
        partnerId: JSON.parse(localStorage.getItem("user"))._id,
      };
      await this.$store
        .dispatch("listSetting/updatelistSetting", payload)
        .then((res) => {})
        .catch((err) => {
          
        });
    },
    async getAllAdminUsers(page, limit = this.limit) {
      const params = `?page=${page}&limit=${limit}&sort=desc`;
      await this.fetchAllAdminUsers(params)
        .then((res) => {
          this.users = res.data.data.docs;
          this.totalData = res.data.data.pagination.total
            ? res.data.data.pagination.total
            : 0;
        })
        .catch((err) => {
          
        });
    },
  },
  computed: {
    totalPage: function () {
      return Math.ceil(this.totalData / this.limit);
    },
  },
  beforeMount() {
    this.getListSetting();
    this.getAllAdminUsers(this.pageNumber, this.limit);
  },
  watch: {
    pageNumber(val) {
      this.getAllAdminUsers(val, this.limit);
    },
    limit(val) {
      this.getAllAdminUsers(this.pageNumber, val);
      this.saveListSetting(val);
    },
  },
};
</script>
